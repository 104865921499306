import { useState, useEffect, useRef } from "react";
import { Table, Image, Row, Col, Spin, Avatar, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getStaffNetworkDetail } from "../../store/staff";
import {
  getErrorEdit,
  getStaffsNetworkDataDetailSelector,
  getStaffLoading,
} from "../../store/staff/selectors";
import { useNavigate } from "react-router-dom";
import displayErrors from "../../utils/displayErrors";
import iconNotSuccessful from "../../images/iconNotSuccessful.svg";
import iconSuccessful from "../../images/iconSuccessful.svg";
import { formatDateString } from "../../utils/utils";
import styles from "./styles.module.css";

export const StaffNetworkEditPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loader = useSelector(getStaffLoading);
  const error = useSelector(getErrorEdit);
  const [width, setWidth] = useState(window.innerWidth);
  const staff = useSelector(getStaffsNetworkDataDetailSelector);
  const [staffId, setStaffId] = useState(null);

  const columns = [
    createColumn(
      "Студия",
      "studio",
      (v) => {
        return <span>{v?.y_title}</span>;
      },
      100
    ),
    createColumn(
      "Сотрудник",
      "user",
      (_, row) => {
        return (
          <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
            <Avatar
              style={{
                width: 25,
                minWidth: 25,
                height: 25,
              }}
              src={row?.y_avatar}
            />
            <Space direction="vertical" style={{ gap: 0 }}>
              <span style={{ textWrap: "nowrap" }}>{row?.y_name}</span>
              <span
                style={{
                  color: "#8B8B8B",
                  fontSize: 12,
                  lineHeight: "14.52px",
                  textWrap: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "170px",
                  display: "flex",
                }}
              >
                {row?.position?.y_title}
              </span>
            </Space>
          </div>
        );
      },
      100
    ),
    createColumn(
      "Нанят",
      undefined,
      (_, row) => {
        return (
          <span style={{ color: "#4B4F55", textWrap: "nowrap" }}>
            {formatDateString(row?.hiring_date)}
          </span>
        );
      },
      100
    ),
    createColumn(
      "Уволен",
      undefined,
      (_, row) => {
        return (
          <span style={{ color: "#4B4F55", textWrap: "nowrap" }}>
            {formatDateString(row?.y_dismissal_date)}
          </span>
        );
      },
      100
    ),
    createColumn(
      "Статус",
      "y_fired",
      (v) => {
        return !v ? "Работает" : "Уволен";
      },
      100
    ),
    createColumn(
      "Работает официально",
      "is_official",
      (v) => {
        return v ? (
          <img src={iconSuccessful} alt="Официально" />
        ) : (
          <img src={iconNotSuccessful} alt="Не официально" />
        );
      },
      100
    ),
    createColumn("Рейтинг", "y_rating", undefined, 100),
  ];

  function createColumn(title, key, render, width, align) {
    return { title, key, dataIndex: key, render, width, align };
  }

  useEffect(() => {
    setStaffId(JSON.parse(sessionStorage.getItem("user"))?.id);
  }, []);

  useEffect(() => {
    if (JSON.parse(sessionStorage.getItem("studio")) && staffId) {
      dispatch(
        getStaffNetworkDetail({
          network_y_id: JSON.parse(sessionStorage.getItem("studio"))?.y_id,
          id: staffId,
        })
      );
    }
  }, [JSON.parse(sessionStorage.getItem("studio"))?.y_id, staffId]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 34,
        overflowY: "auto",
        margin: 0,
        height: "calc(100vh - 200px)",
        alignItems: width < 1210 && "center",
      }}
    >
      {error && displayErrors(error)}
      <Row
        gutter={16}
        style={{
          gap: 34,
          width: "100%",
          flexWrap: "wrap",
          justifyContent: width < 1210 ? "center" : "space-between",
        }}
      >
        <Col
          style={{
            padding: "19px 23px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            minHeight: 450,
          }}
        >
          <Image
            src={staff?.staff[0]?.y_avatar_big || staff?.staff[0]?.y_avatar}
            alt="avatar"
            style={{
              width: 193,
              borderRadius: "50%",
              height: 193,
            }}
          />
        </Col>
        <Col
          style={{
            padding: "19px 23px",
            backgroundColor: "#FFF",
            flex: 1,
            minHeight: 450,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
          <div>
            <p
              style={{
                marginTop: 0,
                marginBottom: 3,
                color: "#8B8B8B",
                fontSize: "10px",
              }}
            >
              Имя
            </p>
            <div
              style={{
                border: "1px solid #4B4A4A33",
                borderRadius: 2,
                padding: "10px 22px",
                backgroundColor: "#F9F9F9",
              }}
            >
              {staff?.name}
            </div>
          </div>
          <div>
            <p
              style={{
                marginTop: 0,
                marginBottom: 3,
                color: "#8B8B8B",
                fontSize: "10px",
              }}
            >
              Пользователь
            </p>
            <div
              style={{
                border: "1px solid #4B4A4A33",
                borderRadius: 2,
                padding: "10px 22px",
                backgroundColor: "#F9F9F9",
                textWrap: "nowrap",
              }}
            >
              {staff?.staff[0]?.user?.external_data?.y_name} -{" "}
              {staff?.staff[0]?.user?.external_data?.y_phone}
            </div>
          </div>
          <div>
            <p
              style={{
                marginTop: 0,
                marginBottom: 3,
                color: "#8B8B8B",
                fontSize: "10px",
              }}
            >
              Дата найма в сеть
            </p>
            <div
              style={{
                border: "1px solid #4B4A4A33",
                borderRadius: 2,
                padding: "10px 22px",
                backgroundColor: "#F9F9F9",
              }}
            >
              {formatDateString(staff?.hiring_date)}
            </div>
          </div>
          <div>
            <p
              style={{
                marginTop: 0,
                marginBottom: 3,
                color: "#8B8B8B",
                fontSize: "10px",
              }}
            >
              Дата увольнения
            </p>
            <div
              style={{
                border: "1px solid #4B4A4A33",
                borderRadius: 2,
                padding: "10px 22px",
                backgroundColor: "#F9F9F9",
              }}
            >
              {formatDateString(staff?.dismissal_date)}
            </div>
          </div>
          <div>
            <p
              style={{
                marginTop: 0,
                marginBottom: 3,
                color: "#8B8B8B",
                fontSize: "10px",
              }}
            >
              Статус
            </p>
            <div
              style={{
                border: "1px solid #4B4A4A33",
                borderRadius: 2,
                padding: "10px 22px",
                backgroundColor: "#F9F9F9",
              }}
            >
              {staff?.is_working}
            </div>
          </div>
        </Col>
        <Col
          style={{
            padding: "19px 23px",
            backgroundColor: "#FFF",
            flex: 1,
            minHeight: 450,
          }}
        >
          <div>
            <p
              style={{
                marginTop: 0,
                marginBottom: 3,
                color: "#8B8B8B",
                fontSize: "10px",
              }}
            >
              Филиалы
            </p>
            <div
              style={{
                border: "1px solid #4B4A4A33",
                borderRadius: 2,
                padding: "10px 22px",
                backgroundColor: "#F9F9F9",
                minHeight: 131,
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              {staff?.studios?.map((item) => {
                return (
                  <p key={item?.id} style={{ margin: 0 }}>
                    {item?.y_title}
                  </p>
                );
              })}
            </div>
          </div>
        </Col>
      </Row>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {error && displayErrors(error)}
        <Table
          columns={columns}
          rowKey={(obj) => obj.id}
          size="small"
          pagination={false}
          loading={loader && <Spin size="large" />}
          className={`${styles.table} ${styles.table_header}`}
          dataSource={staff?.staff || []}
          onRow={(record) => ({
            onClick: () => {
              sessionStorage.setItem("user", JSON.stringify(record));
              sessionStorage.setItem("studio", JSON.stringify(record.studio));
              navigate(`/staff_edit/${record?.studio?.y_id}`);
            },
          })}
        />
      </div>
    </div>
  );
};
