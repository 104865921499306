export const getError = ({ staff }) => staff.errors.error;
export const getSuccess = ({ staff }) => staff.success.success;
export const getStaffDropDownSelector = ({ staff }) => staff.staffDropDown;
export const getStaffDropDownNetworkSelector = ({ staff }) =>
  staff.staffDropDownNetwork;
export const getStaffSelector = ({ staff }) => staff.staff;
export const getStaffsDataSelector = ({ staff }) => staff.staffs;
export const getStaffsDataDetailSelector = ({ staff }) => staff.staffDetail;
export const getStaffsNetworkDataDetailSelector = ({ staff }) =>
  staff.staffNetworkDetail;
export const getPositionDataSelector = ({ staff }) => staff.positionDropDown;
export const getPositionNetworkDataSelector = ({ staff }) =>
  staff.positionNetworkDropDown;
export const getSuccessDelete = ({ staff }) => staff.success.delete;
export const getErrorCreate = ({ staff }) => staff.errors.create;
export const getErrorEdit = ({ staff }) => staff.errors.edit;
export const getStaffNextPage = ({ staff }) => staff.nextPage;
export const getStaffLoading = ({ staff }) => staff.loaders.common;
export const getStaffsDataEditSelector = ({ staff }) => staff.staffEdit;
export const getSuccessDeleteDocument = ({ staff }) =>
  staff.success.deleteDocumnt;
export const getErrorDeleteDocument = ({ staff }) => staff.errors.deleteDocumnt;
export const getSuccessPostDocument = ({ staff }) => staff.success.postDocument;
export const getErrorPostDocument = ({ staff }) => staff.errors.postDocument;
export const getStaffsNetworkDataSelector = ({ staff }) => staff.staffNetwork;
export const getStaffsNetworkNextPage = ({ staff }) => staff.nextNetworkPage;
