import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";
import { logout } from "../login";

const checkFilters = (
  filters,
  filtersArray,
  cursor = false,
  limit = false,
  ordering
) => {
  if (!filters) filters = {};
  if (filtersArray?.length > 0) {
    filtersArray?.map((item) => {
      if (item.key !== "start_date" && item.key !== "end_date") {
        if (Array.isArray(item.value)) {
          filters[item.key] = item.value.join(",");
        } else {
          filters[item.key] = item.value;
        }
      } else "";
    });
  }
  if (ordering) filters.ordering = ordering;

  if (cursor !== false) {
    filters.offset = cursor;
  }

  if (limit !== false) {
    filters.limit = limit;
  }

  return filters;
};

export const getStaffs = createAsyncThunk(
  "getStaffs",
  async (
    { studio_y_id, filters, filtersArray, limit, cursor, pagination, ordering },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { data } = await axiosInstance.get(
        `/studio_staff/studio/${studio_y_id}/staff/`,
        {
          params: checkFilters(filters, filtersArray, cursor, limit, ordering),
        }
      );
      return { data, pagination };
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStaffDetail = createAsyncThunk(
  "getStaffDetail",
  async ({ studio_y_id, id }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/studio_staff/studio/${studio_y_id}/staff/${id}/`
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStaffNetworkDetail = createAsyncThunk(
  "getStaffNetworkDetail",
  async ({ network_y_id, id }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/network_staff/network/${network_y_id}/staff/${id}/`
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPositionDropDown = createAsyncThunk(
  "getPositionDropDown",
  async (studio_y_id, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/studio_positions/studio/${studio_y_id}/positions/`
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPositionNetworkDropDown = createAsyncThunk(
  "getPositionNetworkDropDown",
  async (network_y_id, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/network_positions/network/${network_y_id}/positions/`
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStaffDropDown = createAsyncThunk(
  "getStaffDropDown",
  async (studio_y_id, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/studio_staff/studio/${studio_y_id}/staff/?limit=500`
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStaffDropDownNetwork = createAsyncThunk(
  "getStaffDropDownNetwork",
  async (studio_y_id, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/network_staff/network/${studio_y_id}/staff/?limit=2000`
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStaff = createAsyncThunk(
  "getStaff",
  async ({ studio_y_id, id }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/studio_staff/studio/${studio_y_id}/staff/${id}/`
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStaffNetwork = createAsyncThunk(
  "getStaffNetwork",
  async (
    { network_y_id, filters, filtersArray, limit, cursor, pagination },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { data } = await axiosInstance.get(
        `/network_staff/network/${network_y_id}/staff/`,
        {
          params: checkFilters(filters, filtersArray, cursor, limit),
        }
      );
      return { data, pagination };
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const postStaff = createAsyncThunk(
  "postStaff",
  async ({ studio_y_id, staff }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        `/studio_staff/studio/${studio_y_id}/staff/`,
        staff
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const patchStaff = createAsyncThunk(
  "patchStaff",
  async ({ studio_y_id, id, dataStaff }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.patch(
        `/studio_staff/studio/${studio_y_id}/staff/${id}/`,
        dataStaff
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteStaff = createAsyncThunk(
  "deleteStaff",
  async ({ studio_y_id, id }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(
        `/studio_staff/studio/${studio_y_id}/staff/${id}/`
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteDocumentStaff = createAsyncThunk(
  "deleteDocumentStaff",
  async ({ studio_y_id, staff_id, id }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(
        `/studio_staff_documents/studio/${studio_y_id}/staff/${staff_id}/documents/${id}/`
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const postDocumentStaff = createAsyncThunk(
  "postDocumentStaff",
  async (
    { studio_y_id, staff_id, dataFile },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { data } = await axiosInstance.post(
        `/studio_staff_documents/studio/${studio_y_id}/staff/${staff_id}/documents/`,
        dataFile
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  staffs: null,
  staffNetwork: null,
  staffDetail: null,
  staffNetworkDetail: null,
  staffDropDown: null,
  staffDropDownNetwork: null,
  positionDropDown: null,
  positionNetworkDropDown: null,
  staffEdit: null,
  staff: null,
  nextPage: null,
  nextNetworkPage: null,
  success: {
    success: false,
    create: false,
    delete: false,
    edit: false,
    deleteDocumnt: false,
    postDocument: false,
  },
  errors: {
    error: null,
    errorNetwork: null,
    staffDropDownNetwork: null,
    positionDropDown: null,
    positionNetworkDropDown: null,
    delete: null,
    create: null,
    edit: null,
    deleteDocumnt: null,
    postDocument: null,
  },
  loaders: {
    staffDropDownNetwork: false,
    positionDropDown: false,
    positionNetworkDropDown: false,
    common: false,
  },
};

const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    resetMaster: (state) => {
      state.staff = null;
    },
    staffEdit: (state, action) => {
      state.staffEdit = action.payload;
    },
    clearErrorDelete: (state) => {
      state.errors.delete = null;
    },
    clearErrorCreate: (state) => {
      state.errors.create = null;
    },
    clearErrorEdit: (state) => {
      state.errors.edit = null;
    },
    clearSuccess: (state) => {
      state.success.success = false;
    },
    clearError: (state) => {
      state.errors.error = null;
    },
    clearErrorDocumentDelete: (state) => {
      state.errors.deleteDocumnt = null;
    },
    clearSuccessDocumentDelete: (state) => {
      state.success.deleteDocumnt = false;
    },
    clearErrorDocumentPost: (state) => {
      state.errors.postDocument = null;
    },
    clearSuccessDocumentPost: (state) => {
      state.success.postDocument = false;
    },
  },

  extraReducers: (builder) => {
    //getStaffs
    builder.addCase(getStaffs.pending, (state) => {
      state.loaders.common = true;
      state.nextPage = null;
    });
    builder.addCase(getStaffs.fulfilled, (state, { payload }) => {
      state.staffs = payload;
      state.loaders.common = false;
      state.nextPage = payload?.data?.next;
    });
    builder.addCase(getStaffs.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.error = action.payload?.data;
    });
    //getStaffDetail
    builder.addCase(getStaffDetail.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getStaffDetail.fulfilled, (state, { payload }) => {
      state.staffDetail = payload;
      state.loaders.common = false;
    });
    builder.addCase(getStaffDetail.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.error = action.payload?.data;
    });
    //getStaffNetworkDetail
    builder.addCase(getStaffNetworkDetail.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getStaffNetworkDetail.fulfilled, (state, { payload }) => {
      state.staffNetworkDetail = payload;
      state.loaders.common = false;
    });
    builder.addCase(getStaffNetworkDetail.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.error = action.payload?.data;
    });
    //getPositionDropDown
    builder.addCase(getPositionDropDown.pending, (state) => {
      state.loaders.positionDropDown = true;
    });
    builder.addCase(getPositionDropDown.fulfilled, (state, { payload }) => {
      state.positionDropDown = payload?.results[0]?.position;
      state.loaders.positionDropDown = false;
    });
    builder.addCase(getPositionDropDown.rejected, (state, action) => {
      state.loaders.positionDropDown = false;
      state.errors.positionDropDown = action.payload;
    });
    //getPositionNetworkDropDown
    builder.addCase(getPositionNetworkDropDown.pending, (state) => {
      state.loaders.positionNetworkDropDown = true;
    });
    builder.addCase(
      getPositionNetworkDropDown.fulfilled,
      (state, { payload }) => {
        state.positionNetworkDropDown = payload?.results;
        state.loaders.positionNetworkDropDown = false;
      }
    );
    builder.addCase(getPositionNetworkDropDown.rejected, (state, action) => {
      state.loaders.positionNetworkDropDown = false;
      state.errors.positionNetworkDropDown = action.payload;
    });
    //getStaffDropDown
    builder.addCase(getStaffDropDown.pending, (state) => {
      state.loaders.staffDropDown = true;
    });
    builder.addCase(getStaffDropDown.fulfilled, (state, { payload }) => {
      state.staffDropDown = payload;
      state.loaders.staffDropDown = false;
    });
    builder.addCase(getStaffDropDown.rejected, (state, action) => {
      state.loaders.staffDropDown = false;
      state.errors.staffDropDown = action.payload;
    });
    //getStaff
    builder.addCase(getStaff.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getStaff.fulfilled, (state, { payload }) => {
      state.staff = payload;
      state.loaders.common = false;
    });
    builder.addCase(getStaff.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.error = action.payload;
    });
    //getStaffNetwork
    builder.addCase(getStaffNetwork.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getStaffNetwork.fulfilled, (state, { payload }) => {
      state.staffNetwork = payload;
      state.loaders.common = false;
      state.nextNetworkPage = payload?.data?.next;
    });
    builder.addCase(getStaffNetwork.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.errorNetwork = action.payload;
    });
    //getStaffDropDown
    builder.addCase(getStaffDropDownNetwork.pending, (state) => {
      state.loaders.staffDropDownNetwork = true;
    });
    builder.addCase(getStaffDropDownNetwork.fulfilled, (state, { payload }) => {
      state.staffDropDownNetwork = payload;
      state.loaders.staffDropDownNetwork = false;
    });
    builder.addCase(getStaffDropDownNetwork.rejected, (state, action) => {
      state.loaders.staffDropDownNetwork = false;
      state.errors.staffDropDownNetwork = action.payload;
    });
    // postStaff
    builder.addCase(postStaff.pending, (state) => {
      state.loaders.common = true;
      state.errors.create = null;
    });
    builder.addCase(postStaff.fulfilled, (state) => {
      state.loaders.common = false;
      state.success.create = true;
    });
    builder.addCase(postStaff.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.create = action?.payload;
    });
    // patchStaff
    builder.addCase(patchStaff.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(patchStaff.fulfilled, (state) => {
      state.loaders.common = false;
      state.success.edit = true;
    });
    builder.addCase(patchStaff.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.edit = action?.payload;
    });
    // deleteStaff
    builder.addCase(deleteStaff.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(deleteStaff.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.success.delete = true;
    });
    builder.addCase(deleteStaff.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.delete = action?.payload;
    });
    // deleteDocumentStaff
    builder.addCase(deleteDocumentStaff.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(deleteDocumentStaff.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.success.deleteDocumnt = true;
    });
    builder.addCase(deleteDocumentStaff.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.deleteDocumnt = action?.payload;
    });
    // postDocumentStaff
    builder.addCase(postDocumentStaff.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(postDocumentStaff.fulfilled, (state) => {
      state.loaders.common = false;
      state.success.postDocument = true;
    });
    builder.addCase(postDocumentStaff.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.postDocument =
        action?.payload || action?.payload?.error?.message;
    });
  },
});
export const {
  resetMaster,
  clearErrorDelete,
  clearErrorCreate,
  clearSuccess,
  clearError,
  staffEdit,
  clearErrorEdit,
  clearErrorDocumentDelete,
  clearSuccessDocumentDelete,
  clearErrorDocumentPost,
  clearSuccessDocumentPost,
} = staffSlice.actions;
export default staffSlice.reducer;
