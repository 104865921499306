import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authSlice from "./login";
import userSlice from "./user";
import failsSlice from "./fails";
import studiosSlice from "./studios";
import clientsSlice from "./clients";
import staffSlice from "./staff";
import correctionsSlice from "./corrections";
import networksSlice from "./networks";
import networkComplaintsSlice from "./networkComplaints";
import networkCorrectionsSlice from "./networkCorrections";
import userManagementSlice from "./userManagement";
import paymentsSlice from "./payments";
import typeSlice from "./typeComplaints";
import calculationSlice from "./calculation";

const rootReducer = combineReducers({
  auth: authSlice,
  user: userSlice,
  fails: failsSlice,
  studios: studiosSlice,
  clients: clientsSlice,
  staff: staffSlice,
  corrections: correctionsSlice,
  networks: networksSlice,
  networkComplaints: networkComplaintsSlice,
  networkCorrections: networkCorrectionsSlice,
  userManagement: userManagementSlice,
  payments: paymentsSlice,
  type: typeSlice,
  calculation: calculationSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
