import { useState, useEffect, useRef } from "react";
import {
  Form,
  Upload,
  Image,
  Input,
  Select,
  DatePicker,
  Checkbox,
  Typography,
  Row,
  Col,
  Button,
  Spin,
} from "antd";
import {
  PlusOutlined,
  DownloadOutlined,
  FileTextOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import iconPlus from "../../images/iconPlus.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getPositionDropDown,
  patchStaff,
  clearErrorEdit,
  deleteDocumentStaff,
  getStaffDetail,
  postDocumentStaff,
  clearErrorDocumentDelete,
  clearSuccessDocumentDelete,
  clearSuccessDocumentPost,
  clearErrorDocumentPost,
} from "../../store/staff";
import {
  getPositionDataSelector,
  getErrorEdit,
  getSuccessDeleteDocument,
  getErrorDeleteDocument,
  getStaffsDataDetailSelector,
  getStaffLoading,
  getSuccessPostDocument,
  getErrorPostDocument,
  getError,
} from "../../store/staff/selectors";
import { getUsersStudioDropDown } from "../../store/studios";
import {
  getStudioData,
  getUsersStudioDropDownSelectors,
} from "../../store/studios/selectors";
import {
  reversFormatDateString,
  resetDateFilterBefore,
  isDateWithinTwoWeeks,
} from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import displayErrors from "../../utils/displayErrors";
import styles from "./styles.module.css";

export const StaffEditPage = () => {
  const [formEditStaff] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loader = useSelector(getStaffLoading);
  const error = useSelector(getError);
  const errorEdit = useSelector(getErrorEdit);
  const errorDelete = useSelector(getErrorDeleteDocument);
  const errorPost = useSelector(getErrorPostDocument);
  const [imageUrl, setImageUrl] = useState();
  const [imageFile, setImageFile] = useState(null);
  const successDeleteDocument = useSelector(getSuccessDeleteDocument);
  const successPostDocument = useSelector(getSuccessPostDocument);
  const [width, setWidth] = useState(window.innerWidth);
  const positionList = useSelector(getPositionDataSelector);
  const dataUsers = useSelector(getUsersStudioDropDownSelectors);
  const studioData = useSelector(getStudioData);
  const staff = useSelector(getStaffsDataDetailSelector);
  const [staffId, setStaffId] = useState(null);
  const [isCheckedFamiliar, setIsCheckedFamiliar] = useState(false);
  const [isCheckedTesting, setIsCheckedTesting] = useState(false);
  const [isCheckedOfficial, setIsCheckedOfficial] = useState(false);
  const [click, setClick] = useState(false);
  const ref = useRef(null);

  const onFamiliarChange = (e) => {
    setIsCheckedFamiliar(e.target.checked);
  };
  const onTestingChange = (e) => {
    setIsCheckedTesting(e.target.checked);
  };
  const onOfficialChange = (e) => {
    setIsCheckedOfficial(e.target.checked);
  };

  const handleChangeImg = (info) => {
    if (info.fileList.length > 0) {
      const file = info.fileList[0].originFileObj;
      setImageUrl(URL.createObjectURL(file));
      setImageFile(file);
    } else {
      setImageUrl(null);
      setImageFile(null);
    }
  };

  const handleChangeFile = (info) => {
    const formData = new FormData();
    formData.append("file", info.file);
    formData.append("studio_staff", staff?.id);
    formData.append("description", info?.file?.name);

    dispatch(clearErrorDocumentPost());
    dispatch(
      postDocumentStaff({
        studio_y_id: studioData?.y_id,
        staff_id: staff?.id,
        dataFile: formData,
      })
    );
  };

  const handleDownload = (file) => {
    const link = document.createElement("a");
    const extension = file.file.split(".").pop();
    link.href = file.file;
    link.download = `${file?.description}.${extension}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
        width: 193,
        height: 193,
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
          fontSize: 9,
        }}
      >
        Прикрепите фотографию
      </div>
    </button>
  );

  useEffect(() => {
    dispatch(clearErrorEdit());
    dispatch(clearErrorDocumentPost());
  }, []);

  useEffect(() => {
    setStaffId(JSON.parse(sessionStorage.getItem("user"))?.id);
  }, []);

  useEffect(() => {
    if (studioData && staffId) {
      dispatch(getStaffDetail({ studio_y_id: studioData?.y_id, id: staffId }));
    }
    dispatch(clearErrorDocumentDelete());
    dispatch(clearSuccessDocumentDelete());
    dispatch(clearSuccessDocumentPost());
  }, [studioData?.y_id, staffId, successDeleteDocument, successPostDocument]);

  useEffect(() => {
    if (studioData) {
      dispatch(getUsersStudioDropDown(studioData?.y_id));
    }
  }, [studioData?.y_id]);

  useEffect(() => {
    if (studioData) {
      dispatch(getPositionDropDown(studioData?.y_id));
    }
  }, [studioData?.y_id]);

  useEffect(() => {
    if (staff) {
      setImageUrl(staff?.y_avatar_big || staff?.y_avatar);
      formEditStaff.setFields([
        { name: "y_name", value: staff?.y_name || "" },
        {
          name: "y_dismissal_date",
          value: resetDateFilterBefore(staff?.y_dismissal_date) || null,
        },
        {
          name: "y_rating",
          value: staff?.y_rating || 0,
        },
        {
          name: "hiring_date",
          value: resetDateFilterBefore(staff?.hiring_date) || null,
        },
        {
          name: "position",
          value: {
            value: staff.position?.pk,
            label: staff.position?.y_title,
          },
        },
        {
          name: "user",
          value: {
            value: staff?.user?.external_data?.id,
            label:
              staff?.user?.external_data?.y_name +
                " - " +
                staff?.user?.external_data?.y_phone || "",
          },
        },
        {
          name: "birth_date",
          value: resetDateFilterBefore(staff?.birth_date) || null,
        },
        {
          name: "medical_book_update_date",
          value: resetDateFilterBefore(staff?.medical_book_update_date) || null,
        },
        {
          name: "form_replacement_date",
          value: resetDateFilterBefore(staff?.form_replacement_date) || null,
        },
        {
          name: "salary",
          value: staff?.salary,
        },
        {
          name: "y_chain",
          value: staff?.y_chain?.y_title,
        },
        {
          name: "familiar_with_rules",
          value: staff?.familiar_with_rules || false,
        },
        {
          name: "y_fired",
          value: staff?.y_fired ? "Уволен" : "Работает",
        },
        {
          name: "studios",
          value:
            staff?.studios && staff.studios.length > 0
              ? staff.studios[0].y_title
              : "",
        },
        {
          name: "passed_testing",
          value: staff?.passed_testing || false,
        },
        // {
        //   name: "pub_date",
        //   value: resetDateFilterBefore(openCorrectionsEdit?.record?.pub_date),
        // },
      ]);
      setIsCheckedFamiliar(staff?.familiar_with_rules);
      setIsCheckedTesting(staff?.passed_testing);
      setIsCheckedOfficial(staff?.is_official);
    }
  }, [formEditStaff, staff, successDeleteDocument]);

  return (
    <Form
      layout="vertical"
      form={formEditStaff}
      onFinish={(data) => {
        //let formData = new FormData();

        if (data.user) {
          data.user = data?.user?.value;
        }

        data.birth_date = reversFormatDateString(
          data?.birth_date?.$d?.toLocaleDateString("ru-RU")
        );
        data.form_replacement_date = reversFormatDateString(
          data?.form_replacement_date?.$d?.toLocaleDateString("ru-RU")
        );
        if (data.position) {
          data.position = data.position?.value;
        }

        data.hiring_date = reversFormatDateString(
          data?.hiring_date?.$d.toLocaleDateString("ru-RU")
        );
        data.medical_book_update_date = reversFormatDateString(
          data?.medical_book_update_date?.$d?.toLocaleDateString("ru-RU")
        );

        data.y_dismissal_date = reversFormatDateString(
          data?.y_dismissal_date?.$d?.toLocaleDateString("ru-RU")
        );

        data.familiar_with_rules = isCheckedFamiliar;
        data.passed_testing = isCheckedTesting;
        data.is_official = isCheckedOfficial;

        delete data.user;
        delete data.y_name;
        delete data.y_fired;
        delete data.y_chain;
        delete data.studios;
        delete data.position;
        delete data.y_rating;
        delete data.y_dismissal_date;
        // for (let key of Object.keys(data)) {
        //   if (data[key] !== undefined && data[key] !== "") {
        //     formData.append(`${key}`, data[key]);
        //   }
        // }

        dispatch(clearErrorEdit());
        dispatch(
          patchStaff({
            studio_y_id: studioData?.y_id,
            id: staff?.id,
            dataStaff: data,
          })
        ).then((state) => {
          if (!state?.payload?.errors) {
            navigate(`/staff/${studioData?.y_id}`);
          }
        });
      }}
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 34,
        overflowY: "auto",
        margin: 0,
        height: "calc(100vh - 140px)",
        alignItems: width < 1210 && "center",
      }}
    >
      {(error || errorDelete || errorEdit) &&
        displayErrors(error || errorDelete || errorEdit)}
      <Row
        gutter={16}
        style={{
          gap: 34,
          width: "100%",
          flexWrap: "wrap",
          justifyContent: width < 1210 ? "center" : "space-between",
        }}
      >
        <Col
          style={{
            padding: "19px 23px",
            backgroundColor: "#FFF",
            flex: 1,
            minWidth: 320,
          }}
        >
          <Form.Item
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 14,
            }}
          >
            <Upload
              listType="picture-circle"
              showUploadList={false}
              beforeUpload={() => false}
              accept=".jpeg,.png,.svg+xml,.jpg"
              onChange={handleChangeImg}
              maxCount={1}
              disabled={true}
            >
              {imageUrl ? (
                <Image
                  src={imageUrl}
                  alt="avatar"
                  style={{
                    width: 193,
                    borderRadius: "50%",
                    height: 193,
                  }}
                  onClick={(evt) => {
                    evt.stopPropagation();
                  }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>

          <Form.Item
            name="y_name"
            label="Имя"
            style={{ width: "100%", marginBottom: 14 }}
          >
            <Input
              disabled={true}
              placeholder="Введите имя"
              style={{ borderRadius: 2 }}
            />
          </Form.Item>

          <Form.Item
            name="position"
            label="Должность"
            style={{ width: "100%", marginBottom: 14 }}
          >
            <Select
              disabled={true}
              showSearch={true}
              allowClear={true}
              optionFilterProp="label"
              style={{ borderRadius: 2 }}
              options={positionList?.map((option) => {
                return {
                  value: option.pk,
                  label: option.y_title,
                };
              })}
              placeholder="Выберите должность"
            />
          </Form.Item>

          <Form.Item
            name="y_chain"
            label="Сети"
            style={{ width: "100%", marginBottom: 0 }}
          >
            <Select
              disabled={true}
              showSearch={true}
              allowClear={true}
              optionFilterProp="label"
              style={{ borderRadius: 2 }}
              options={positionList?.map((option) => {
                return {
                  value: option.pk,
                  label: option.y_title,
                };
              })}
              placeholder="Выберите сеть"
            />
          </Form.Item>
        </Col>
        <Col
          style={{
            padding: "19px 23px",
            backgroundColor: "#FFF",
            flex: 1,
            minWidth: 320,
          }}
        >
          <Form.Item
            name="user"
            label="Пользователь"
            style={{ width: "100%", marginBottom: 14 }}
          >
            <Select
              disabled={true}
              style={{ borderRadius: 2 }}
              options={dataUsers?.map((option) => {
                return {
                  value: option.id,
                  label: option?.external_data?.y_name,
                };
              })}
              placeholder="Выберите пользователя"
            />
          </Form.Item>

          <Form.Item
            name="y_dismissal_date"
            label="Дата увольнения"
            // rules={[
            //   {
            //     required: true,
            //     message: "Необходимо выбрать дату",
            //   },
            // ]}
            style={{ width: "100%", marginBottom: 14 }}
          >
            <DatePicker
              disabled={true}
              format="DD.MM.YYYY"
              cellWidth={10}
              timeColumnWidth={10}
              presetsMaxWidth={10}
              style={{ width: "100%", borderRadius: 2 }}
              placeholder="Выберите дату"
            />
          </Form.Item>

          <Form.Item
            name="y_fired"
            label="Статус"
            style={{ width: "100%", marginBottom: 14 }}
          >
            <Input
              disabled={true}
              placeholder="Введите статус"
              style={{ borderRadius: 2 }}
            />
          </Form.Item>

          <Form.Item
            name="y_rating"
            label="Рейтинг"
            style={{ width: "100%", marginBottom: 14 }}
          >
            <Input
              disabled={true}
              placeholder="Введите рейтинг"
              style={{ borderRadius: 2 }}
            />
          </Form.Item>

          <Form.Item
            name="studios"
            label="Филиалы"
            style={{ width: "100%", marginBottom: 14 }}
          >
            <Input
              disabled={true}
              placeholder="Выберите филиал"
              style={{ borderRadius: 2 }}
            />
          </Form.Item>
        </Col>
        <Col
          style={{
            padding: "19px 23px",
            backgroundColor: "#FFF",
            flex: 1,
            minWidth: 320,
          }}
          ref={ref}
        >
          <Form.Item
            name="hiring_date"
            label="Дата найма в филиал"
            // rules={[
            //   {
            //     required: true,
            //     message: "Необходимо выбрать дату",
            //   },
            // ]}
            style={{ width: "100%", marginBottom: 14 }}
          >
            <DatePicker
              disabled={!click && true}
              format="DD.MM.YYYY"
              cellWidth={10}
              timeColumnWidth={10}
              presetsMaxWidth={10}
              style={{ width: "100%", borderRadius: 2 }}
              placeholder="Выберите дату"
            />
          </Form.Item>

          <Form.Item
            name="birth_date"
            label="Дата рождения"
            style={{ width: "100%", marginBottom: 14 }}
          >
            <DatePicker
              disabled={!click && true}
              format="DD.MM.YYYY"
              cellWidth={10}
              timeColumnWidth={10}
              presetsMaxWidth={10}
              style={{ width: "100%", borderRadius: 2 }}
              placeholder="Выберите дату"
            />
          </Form.Item>

          <Form.Item
            name="medical_book_update_date"
            label="Дата обновления медкнижки"
            style={{ width: "100%", marginBottom: 14 }}
          >
            <DatePicker
              disabled={!click && true}
              format="DD.MM.YYYY"
              cellWidth={10}
              timeColumnWidth={10}
              presetsMaxWidth={10}
              style={{
                width: "100%",
                borderRadius: 2,
                backgroundColor:
                  isDateWithinTwoWeeks(staff?.medical_book_update_date) &&
                  "#DAF7FA",
              }}
              placeholder="Выберите дату"
            />
          </Form.Item>

          <Form.Item
            name="form_replacement_date"
            label="Дата обновления формы"
            style={{ width: "100%", marginBottom: 14 }}
          >
            <DatePicker
              disabled={!click && true}
              format="DD.MM.YYYY"
              cellWidth={10}
              timeColumnWidth={10}
              presetsMaxWidth={10}
              style={{
                width: "100%",
                borderRadius: 2,
                backgroundColor:
                  isDateWithinTwoWeeks(staff?.form_replacement_date) &&
                  "#DAF7FA",
              }}
              placeholder="Выберите дату"
            />
          </Form.Item>

          {/* <Form.Item
            name="salary"
            label="Оклад"
            rules={[
              {
                required: true,
                message: `Необходимо написать оклад`,
              },
            ]}
            style={{ width: "100%", marginBottom: 14 }}
          >
            <Input
              disabled={!click && true}
              placeholder="Введите оклад"
              style={{ borderRadius: 2 }}
            />
          </Form.Item> */}

          <Form.Item
            name="familiar_with_rules"
            label=""
            style={{ width: "100%", marginBottom: 14 }}
          >
            <div style={{ display: "flex", gap: 17 }}>
              <Checkbox
                disabled={!click && true}
                checked={isCheckedFamiliar}
                onChange={onFamiliarChange}
              />
              <Typography.Text>Ознакомлен с правилами</Typography.Text>
            </div>
          </Form.Item>

          <Form.Item
            name="passed_testing"
            label=""
            style={{ width: "100%", marginBottom: 14 }}
          >
            <div style={{ display: "flex", gap: 17 }}>
              <Checkbox
                disabled={!click && true}
                checked={isCheckedTesting}
                onChange={onTestingChange}
              />
              <Typography.Text>Прошел тестирование</Typography.Text>
            </div>
          </Form.Item>

          <Form.Item
            name="is_official"
            label=""
            style={{ width: "100%", marginBottom: 0 }}
          >
            <div style={{ display: "flex", gap: 17 }}>
              <Checkbox
                disabled={!click && true}
                checked={isCheckedOfficial}
                onChange={onOfficialChange}
              />
              <Typography.Text>Работает официально</Typography.Text>
            </div>
          </Form.Item>
        </Col>
      </Row>
      <Row
        gutter={16}
        style={{
          width: "100%",
          gap: 34,
          justifyContent: width < 1210 ? "center" : "space-between",
        }}
      >
        <Col
          style={{
            padding: "19px 23px",
            backgroundColor: "#FFF",
            flex: 1,
            minWidth: 320,
            display: "flex",
            flexDirection: "column",
            minHeight: 467,
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", gap: 14 }}>
            <Typography.Text style={{ fontSize: 16 }}>
              Прикрепленные документы:
            </Typography.Text>
            {staff?.documents?.length > 0 ? (
              loader ? (
                <Spin size="large" />
              ) : (
                <Row style={{ width: "100%", gap: 9 }}>
                  {errorPost && displayErrors(errorPost)}
                  {staff.documents.map((item) => {
                    return (
                      <Col
                        key={item.id}
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          padding: 0,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 30,
                          }}
                        >
                          <FileTextOutlined
                            style={{ fontSize: 44, color: "#8090B8" }}
                          />
                          {item.description || "Документ"}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 10,
                          }}
                        >
                          <DownloadOutlined
                            style={{
                              cursor: "pointer",
                              fontSize: 24,
                              color: "#8090B8",
                            }}
                            onClick={() => handleDownload(item)}
                          />
                          <DeleteOutlined
                            style={{
                              cursor: "pointer",
                              fontSize: 24,
                              color: "#8090B8",
                            }}
                            onClick={() =>
                              dispatch(
                                deleteDocumentStaff({
                                  studio_y_id: studioData?.y_id,
                                  staff_id: staff?.id,
                                  id: item.id,
                                })
                              )
                            }
                          />
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              )
            ) : (
              <span style={{ textAlign: "center" }}>
                Документы еще не загружены
              </span>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
              marginTop: 20,
            }}
          >
            <Upload
              showUploadList={false}
              beforeUpload={() => false}
              accept=".pdf"
              maxCount={1}
              onChange={handleChangeFile}
            >
              <Button
                type="primary"
                style={{
                  backgroundColor: "#76CEC9",
                  width: 173,
                  height: 36,
                  borderRadius: 2,
                }}
              >
                Новый документ
              </Button>
            </Upload>
          </div>
        </Col>
        {width > 1175 && (
          <>
            <Col
              style={{
                flex: 1,
                minWidth: 320,
                padding: "19px 23px",
              }}
            ></Col>
            <Col
              style={{
                flex: 1,
                minWidth: 320,
                padding: "19px 23px",
              }}
            ></Col>
          </>
        )}
      </Row>
      <div style={{ position: "fixed", top: 13, right: 22 }}>
        {!click && (
          <Button
            type="primary"
            style={{ backgroundColor: "#76CEC9" }}
            className={styles.button}
            onClick={() => {
              setClick(!click);
              if (ref.current) {
                ref.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }
            }}
          >
            <img src={iconPlus} alt="plus" />
            {width > 640 && (
              <span className={styles.button_text}>Редактировать</span>
            )}
          </Button>
        )}
        {click && (
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: "#4D7CD6" }}
            className={styles.button}
          >
            <CheckCircleOutlined style={{ fontSize: 18 }} />
            {width > 640 && (
              <span className={styles.button_text}>Сохранить</span>
            )}
          </Button>
        )}
      </div>
    </Form>
  );
};
