import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";
import { logout } from "../login";

const checkFilters = (filters, filtersArray, cursor = false, limit = false) => {
  if (!filters) filters = {};

  if (filtersArray?.length > 0) {
    filtersArray?.map((item) => {
      if (item.key !== "start_date" && item.key !== "end_date") {
        if (Array.isArray(item.value)) {
          filters[item.key] = item.value.join(",");
        } else {
          filters[item.key] = item.value;
        }
      } else "";
    });
  }

  if (cursor !== false) {
    filters.offset = cursor;
  }

  if (limit !== false) {
    filters.limit = limit;
  }

  return filters;
};

export const getPayments = createAsyncThunk(
  "getPayments",
  async (
    { studio_y_id, filters, filtersArray, limit, cursor, pagination },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axiosInstance.get(
        `/studio_salary_paid/studio/${studio_y_id}/salary_paid/`,
        {
          params: checkFilters(filters, filtersArray, cursor, limit),
        }
      );
      return { data, pagination };
    } catch (err) {
      if (err.code === "ERR_NETWORK") return rejectWithValue(err);
      else return rejectWithValue(err.response);
    }
  }
);

export const getPaymentsDropDown = createAsyncThunk(
  "getPaymentsDropDown",
  async ({ studio_y_id }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/studio_salary_paid/studio/${studio_y_id}/accounts/`
      );
      return { data };
    } catch (err) {
      if (err.code === "ERR_NETWORK") return rejectWithValue(err);
      else return rejectWithValue(err.response);
    }
  }
);

export const getPaymentsСheck = createAsyncThunk(
  "getPaymentsСheck",
  async ({ studio_y_id, id }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/studio_salary_paid/studio/${studio_y_id}/check_transaction/${id}/`
      );
      return { data };
    } catch (err) {
      if (err.code === "ERR_NETWORK") return rejectWithValue(err);
      else return rejectWithValue(err.response);
    }
  }
);

export const postPayments = createAsyncThunk(
  "postPayments",
  async ({ studio_y_id, payments }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        `/studio_salary_paid/studio/${studio_y_id}/post_salaries/`,
        payments
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const deletePayments = createAsyncThunk(
  "deletePayments",
  async ({ studio_y_id, id }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(
        `/studio_salary_paid/studio/${studio_y_id}/salary_paid/${id}/`
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  payments: null,
  openPayments: false,
  paymentsDropDown: null,
  paymentsCheck: null,
  nextPage: null,
  success: {
    success: false,
    create: false,
    edit: false,
    check: false,
  },
  errors: {
    error: null,
    edit: null,
    create: null,
    delete: null,
    check: null,
  },
  loaders: {
    common: false,
    check: false,
  },
};

const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    clearError: (state) => {
      state.errors.error = null;
    },
    clearErrorEdit: (state) => {
      state.errors.edit = null;
    },
    clearErrorCreate: (state) => {
      state.errors.create = null;
    },
    clearErrorCheck: (state) => {
      state.errors.check = null;
    },
    setOpenPayments: (state) => {
      state.openPayments = !state.openPayments;
    },
    clearSuccess: (state) => {
      state.success.success = false;
    },
    clearSuccessCreate: (state) => {
      state.success.create = false;
    },
    clearSuccessEdit: (state) => {
      state.success.edit = false;
    },
    clearSuccessCheck: (state) => {
      state.success.check = false;
    },
    clearErrorTypeDropDown: (state) => {
      state.errors.typeDropDown = null;
    },
  },

  extraReducers: (builder) => {
    // getPayments
    builder.addCase(getPayments.pending, (state) => {
      state.loaders.common = true;
      state.errors.error = null;
      state.nextPage = null;
    });
    builder.addCase(getPayments.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.payments = payload;
      state.nextPage = payload?.data.next;
    });
    builder.addCase(getPayments.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.error = action?.payload?.data;
    });
    // getPaymentsDropDown
    builder.addCase(getPaymentsDropDown.pending, (state) => {
      state.loaders.common = true;
      state.errors.error = null;
    });
    builder.addCase(getPaymentsDropDown.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.paymentsDropDown = payload.data.data;
    });
    builder.addCase(getPaymentsDropDown.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.error = action?.payload?.data;
    });
    // getPaymentsСheck
    builder.addCase(getPaymentsСheck.pending, (state) => {
      state.loaders.check = true;
      state.errors.check = null;
    });
    builder.addCase(getPaymentsСheck.fulfilled, (state, { payload }) => {
      state.loaders.check = false;
      state.success.check = true;
      state.paymentsCheck = payload;
    });
    builder.addCase(getPaymentsСheck.rejected, (state, action) => {
      state.loaders.check = false;
      state.errors.check = action?.payload?.data;
    });
    // postPayments
    builder.addCase(postPayments.pending, (state) => {
      state.loaders.common = true;
      state.errors.create = null;
    });
    builder.addCase(postPayments.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.success.create = true;
    });
    builder.addCase(postPayments.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.create = action?.payload;
    });
    // deletePayments
    builder.addCase(deletePayments.pending, (state) => {
      state.loaders.common = true;
      state.errors.delete = null;
    });
    builder.addCase(deletePayments.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.success.success = true;
      // state.fails = payload;
    });
    builder.addCase(deletePayments.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.delete = action?.payload;
    });
  },
});
export const {
  clearError,
  setOpenPayments,
  clearErrorEdit,
  clearErrorCreate,
  clearSuccess,
  clearSuccessEdit,
  clearSuccessCreate,
  clearErrorCheck,
  clearSuccessCheck,
} = paymentsSlice.actions;
export default paymentsSlice.reducer;
