import { useState, useEffect } from "react";
import { Button, Input, Modal, Space, Typography } from "antd";
import {
  RightOutlined,
  LeftOutlined,
  SearchOutlined,
  CloseOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import {
  setOpenUserManagement,
  patchUserManagement,
  clearSuccessPatch,
} from "../../store/userManagement";
import { getSuccessPatch } from "../../store/userManagement/selectors";
import { searchName } from "../../utils/utils";
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";

const { Title } = Typography;

export const UserModal = ({ isModalOpen, data }) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [searchAdded, setSearchAdded] = useState("");
  const [selectedAvailable, setSelectedAvailable] = useState([]);
  const [selectedCurrent, setSelectedCurrent] = useState([]);
  const [usersAdded, setUsersAdded] = useState([]);
  const [usersDeleted, setUsersDeleted] = useState([]);
  const patchSuccess = useSelector(getSuccessPatch);

  function deleteUsers() {
    setUsersDeleted((v) => [
      ...v,
      ...(selectedCurrent || []).filter(
        (t) =>
          v.findIndex((x) => x.id == t.id) < 0 &&
          data[0]?.users_with_access?.findIndex((x) => x.id == t.id) >= 0
      ),
    ]);
    setUsersAdded((v) =>
      (v || []).filter(
        (t) => selectedCurrent.findIndex((x) => x.id == t.id) < 0
      )
    );
    setSelectedCurrent([]);
  }

  function addUsers() {
    setUsersAdded((v) => [
      ...v,
      ...(selectedAvailable || []).filter(
        (t) =>
          v.findIndex((x) => x.id == t.id) < 0 &&
          data[0]?.users_with_access?.findIndex((x) => x.id == t.id) < 0
      ),
    ]);
    setUsersDeleted((v) =>
      (v || []).filter(
        (t) => selectedAvailable.findIndex((x) => x.id == t.id) < 0
      )
    );
    setSelectedAvailable([]);
  }

  function deleteAllUsers() {
    setUsersDeleted(
      data[0]?.users_with_access?.filter((v) =>
        v.y_name?.toLowerCase().includes(searchAdded)
      )
    );
    setUsersAdded((v) =>
      (v || []).filter((v) => !v.y_name?.toLowerCase().includes(searchAdded))
    );
    setSelectedCurrent([]);
  }

  function addAllUsers() {
    setUsersAdded(
      data[0]?.users_without_access?.filter(
        (v) =>
          v.y_name?.toLowerCase().includes(search) &&
          data[0]?.users_with_access?.findIndex((x) => x.id == v.id) < 0
      )
    );
    setUsersDeleted((v) =>
      (v || []).filter((v) => !v.y_name?.toLowerCase().includes(search))
    );
    setSelectedCurrent([]);
  }

  function saveChanges() {
    if (usersAdded?.length > 0 || usersDeleted?.length > 0) {
      const usersAddedIds = usersAdded?.map((user) => user.id);
      const existingUserIds = data[0]?.users_with_access?.map(
        (user) => user.id
      );
      const mergedUserIds = {
        studio: data[0]?.studio?.id,
        users_with_access: [
          ...usersAddedIds,
          ...existingUserIds.filter(
            (id) => !usersDeleted?.some((user) => user.id === id)
          ),
        ],
      };

      dispatch(
        patchUserManagement({
          studio_y_id: data[0]?.studio?.y_id,
          id: data[0]?.id,
          users: mergedUserIds,
        })
      );
      dispatch(clearSuccessPatch());
    }
  }

  useEffect(() => {
    setSelectedAvailable([]);
    setSelectedCurrent([]);
    setSearch("");
    setSearchAdded("");
    setUsersAdded([]);
    setUsersDeleted([]);
  }, [isModalOpen]);

  useEffect(() => {
    if (!patchSuccess) return;
    setSelectedAvailable([]);
    setSelectedCurrent([]);
    setUsersAdded([]);
    setUsersDeleted([]);
  }, [patchSuccess]);

  return (
    <div className={styles.drawer}>
      <Modal
        className={styles.modal}
        width={"min(100%, 870px)"}
        open={isModalOpen}
        onClose={() => {
          dispatch(clearSuccessPatch());
          dispatch(setOpenUserManagement());
        }}
        onCancel={() => {
          dispatch(clearSuccessPatch());
          dispatch(setOpenUserManagement());
        }}
        footer={
          <>
            <Button
              className={styles.buttonClose}
              onClick={() => {
                dispatch(clearSuccessPatch());
                dispatch(setOpenUserManagement());
              }}
            >
              Отменить и закрыть
            </Button>
            <Button
              type="primary"
              className={styles.button}
              onClick={() => {
                saveChanges();
              }}
            >
              Сохранить
            </Button>
          </>
        }
        maskClosable={false}
      >
        <Title level={2}>
          Добавить пользователя в{" "}
          <span
            // level={3}
            style={{
              // margin: 0,
              marginBottom: 16,
              color: "#8090B8",
              fontWeight: "normal",
            }}
          >
            {data[0]?.studio?.y_title}
          </span>
        </Title>

        {/* {errors && displayErrors(errors)} */}

        <div className={styles.root}>
          {/* {loaderAgents && <PreloaderOver />} */}

          <div className={styles.card}>
            <div className={styles.title}>
              <Title level={5} className={styles.cardTitle}>
                <span>Доступные пользователи</span>
                <span>
                  {data[0]?.users_without_access?.length -
                    usersAdded.length +
                    usersDeleted.length || "0"}
                </span>
              </Title>
            </div>
            <Input
              prefix={<SearchOutlined style={{ color: "black" }} />}
              placeholder="Найти пользователя"
              onChange={(evt) => setSearch(evt.target.value.toLowerCase())}
              value={search}
              suffix={
                <CloseOutlined
                  style={{ color: search ? "red" : "black" }}
                  onClick={() => setSearch("")}
                />
              }
              style={{ color: "black" }}
            />
            <div
              className={styles.cardBody}
              id={"available"}
              style={{ height: "270px", overflowY: "auto" }}
            >
              {itemsSelect(
                data[0]?.users_without_access?.filter((v) => {
                  return (
                    searchName(v, search) &&
                    data[0]?.users_with_access?.findIndex(
                      (x) => x.y_name == v.y_name
                    ) < 0
                  );
                }),
                selectedAvailable,
                setSelectedAvailable,
                usersAdded,
                usersDeleted.filter((v) => searchName(v, search)),
                false
              )}
            </div>
          </div>
          <div className={styles.buttons}>
            {button("Добавить\nвсё", <DoubleRightOutlined />, addAllUsers)}
            {button(
              "Добавить",
              <RightOutlined />,
              addUsers,
              selectedAvailable.length == 0
            )}
            {button(
              "Удалить",
              <LeftOutlined />,
              deleteUsers,
              selectedCurrent.length == 0
            )}
            {button("Удалить\nвсё", <DoubleLeftOutlined />, deleteAllUsers)}
          </div>
          <div className={styles.card}>
            <div className={styles.title}>
              <Title level={5} className={styles.cardTitle}>
                <span>Пользователи в группе</span>
                <span>
                  {data[0]?.users_with_access?.length +
                    usersAdded.length -
                    usersDeleted.length || 0}
                </span>
              </Title>
            </div>
            <Input
              prefix={<SearchOutlined style={{ color: "black" }} />}
              placeholder="Найти пользователя"
              onChange={(evt) => setSearchAdded(evt.target.value.toLowerCase())}
              value={searchAdded}
              suffix={
                <CloseOutlined
                  style={{ color: searchAdded ? "red" : "black" }}
                  onClick={() => setSearchAdded("")}
                />
              }
              style={{ color: "black" }}
            />
            <div
              className={styles.cardBody}
              style={{ height: "270px", overflowY: "auto" }}
            >
              {itemsSelect(
                data[0]?.users_with_access?.filter((c) => {
                  return c.y_name.toLowerCase().includes(searchAdded);
                }),
                selectedCurrent,
                setSelectedCurrent,
                usersDeleted,
                usersAdded.filter((v) =>
                  v.y_name.toLowerCase().includes(searchAdded)
                ),
                true
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

function button(text, icon, onClick, disabled = false) {
  return (
    <button className={styles.buttonUser} onClick={onClick} disabled={disabled}>
      <Space direction="vertical" size={4}>
        <div style={{ fontSize: 20 }}>{icon}</div>
        <div style={{ lineHeight: "1em" }}>
          {text.split("\n").map((v, i) => (
            <div key={i + "button"}>{v}</div>
          ))}
        </div>
      </Space>
    </button>
  );
}

function itemsSelect(items, selected, setSelected, hidden, added, red) {
  const filter = (added || [])
    .map((v) => ({ ...v, _added: true }))
    .concat(
      items?.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.y_name === value.y_name) &&
          hidden.findIndex((t) => t.y_name === value.y_name) < 0
      ) || []
    );

  return filter?.map((v) => {
    return (
      <div
        key={v.y_name}
        onClick={() =>
          setSelected((cur) =>
            cur.findIndex((t) => t.y_name === v.y_name) > -1
              ? cur.filter((it) => it.y_name !== v.y_name)
              : [...cur, v]
          )
        }
        className={[
          styles.item,
          v._added && (red ? styles.itemRemoved : styles.itemAdded),
          selected.findIndex((t) => t.y_name === v.y_name) > -1 &&
            styles.itemSelected,
        ]
          .filter((v) => !!v)
          .join(" ")}
      >
        <span>
          <CheckOutlined />
        </span>
        <span>{v.y_name}</span>
      </div>
    );
  });
}
