import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, Typography, Descriptions, Row, Col, Tag, Tooltip } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  DescItem,
  convertToLocalDate,
  formatDateString,
} from "../../utils/utils";
import {
  getPaymentsСheck,
  clearErrorCheck,
  clearSuccessCheck,
} from "../../store/payments";
import {
  getPaymentsLoadingCheck,
  getSuccessCheck,
  getErrorCheck,
  getCheck,
} from "../../store/payments/selectors";
import styles from "./styles.module.css";

export const PaymentsDetailModal = ({ openDetails, setOpenDetails }) => {
  const dispatch = useDispatch();
  const errorCheck = useSelector(getErrorCheck);
  const successCheck = useSelector(getSuccessCheck);
  const loadingCheck = useSelector(getPaymentsLoadingCheck);
  const dataCheck = useSelector(getCheck);

  useEffect(() => {
    if (openDetails?.record) {
      dispatch(
        getPaymentsСheck({
          studio_y_id: openDetails?.record?.studio?.y_id,
          id: openDetails?.record?.id,
        })
      );
    }
  }, [openDetails?.record?.id]);

  return (
    <Drawer
      open={openDetails?.open}
      onCancel={() => {
        dispatch(clearErrorCheck());
        dispatch(clearSuccessCheck());
        setOpenDetails({ open: false });
      }}
      onClose={() => {
        dispatch(clearErrorCheck());
        dispatch(clearSuccessCheck());
        setOpenDetails({ open: false });
      }}
      footer={null}
      title={
        <Typography.Title style={{ margin: 0 }} level={3}>
          Детали выплаты
        </Typography.Title>
      }
      width={500}
      maskClosable={true}
    >
      <Descriptions
        bordered
        column={1}
        labelStyle={{ color: "black", width: 200 }}
        style={{ marginTop: "20px" }}
      >
        {DescItem("ID выплаты", openDetails?.record?.id)}
        {DescItem("Студия", openDetails?.record?.studio?.y_title)}
        {DescItem(
          "Дата начала периода",
          formatDateString(openDetails?.record?.period_start_date)
        )}
        {DescItem(
          "Дата конца периода",
          formatDateString(openDetails?.record?.period_end_date)
        )}
        {DescItem(
          "Дата время выплаты",
          convertToLocalDate(openDetails?.record?.pay_date_time)
        )}
        {DescItem("Пользователь", openDetails?.record?.master?.y_name)}
        {DescItem(
          "Перенесена в YClients",
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {!loadingCheck &&
            !errorCheck &&
            (successCheck || dataCheck?.data?.is_check) ? (
              <Tag icon={<CheckCircleOutlined />} color="success">
                Внесена
              </Tag>
            ) : loadingCheck ? (
              <Tag icon={<SyncOutlined spin />} color="processing">
                Проверка
              </Tag>
            ) : errorCheck || !dataCheck?.data?.is_check ? (
              <Tag icon={<CloseCircleOutlined />} color="error">
                Не внесена
              </Tag>
            ) : null}
            {!loadingCheck && (
              <Tooltip
                color="#FFFFFF"
                title={
                  <span style={{ color: "#4B4F55" }}>Проверить выплату</span>
                }
              >
                <SyncOutlined
                  onClick={() => {
                    dispatch(clearErrorCheck());
                    dispatch(clearSuccessCheck());
                    dispatch(
                      getPaymentsСheck({
                        studio_y_id: openDetails?.record?.studio?.y_id,
                        id: openDetails?.record?.id,
                      })
                    );
                  }}
                  className={styles.icon}
                />
              </Tooltip>
            )}
          </div>
        )}
        {DescItem(
          "Связанные коррекции списком",
          openDetails?.record?.related_corrections?.length > 0
            ? openDetails?.record?.related_corrections?.map((item) => {
                return (
                  <Row key={item?.id}>
                    <Col>{item?.corr_type}</Col>
                  </Row>
                );
              })
            : "Нет данных"
        )}
      </Descriptions>
    </Drawer>
  );
};
