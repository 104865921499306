import React from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../store/login";
import { setClearUser } from "../../store/user";

export const MenuProfile = ({ setOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      <div className={styles.background} onClick={() => setOpen(false)}></div>
      <div className={styles.menu}>
        <div className={styles.itemMenu}>Профиль</div>
        <div
          className={styles.itemMenu}
          onClick={() => {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            dispatch(logout());
            dispatch(setClearUser());
            navigate("/login");
          }}
        >
          Выйти
        </div>
      </div>
    </>
  );
};
